import LoadingSpinner from '@components/common/loading/LoadingSpinner';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useUserInfoQuery } from '@apis/query/user';
import { UserType } from '@asset/enum/UserEnum';
import { MY_CLASS_MAIN_URL_PREFIX } from 'routes/common';

const index = () => {
  const { data: user } = useUserInfoQuery();
  const router = useRouter();

  useEffect(() => {
    if (user) {
      if (user.type === UserType.TEACHER) router.push(MY_CLASS_MAIN_URL_PREFIX);
      else if (user.type === UserType.STUDENT) router.push('/dashboard');
    } else {
      router.push('/account/login');
    }
  });

  return <>{user ? <LoadingSpinner /> : <></>}</>;
};

export default index;
